import React from "react";
import { Section } from "./Card.style";

interface IProps {}

export type ICardProps = IProps & JSX.IntrinsicElements["section"];

const Card: React.FC<ICardProps> = ({ className, children, onClick = () => {} }) => (
  <Section className={className} onClick={onClick}>
    {children}
  </Section>
);

export default Card;
