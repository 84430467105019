import React, { useRef } from "react";
import { IPopoverRef, Direction } from "@ddm-design-system/popover";
import * as InspectorTooltipStyles from "./InspectorTooltip.style";

export interface IInspectorTooltipProps {
  children: string | JSX.Element;
  className?: string;
  direction?: Direction;
  title: string | JSX.Element;
  renderHeader: () => JSX.Element;
  zIndex?: number;
}

const InspectorTooltip: React.FC<IInspectorTooltipProps> = ({
  children,
  className,
  direction = "bottom",
  title,
  renderHeader,
  zIndex
}) => {
  const ref = useRef<IPopoverRef>(null);

  return (
    <InspectorTooltipStyles.StyledPopover
      centered
      className={className}
      direction={direction}
      showOnHover
      renderHeader={renderHeader}
      ref={ref}
      zIndex={zIndex}
    >
      <>
        {typeof title === "string" ? (
          <InspectorTooltipStyles.StyledDescriptionMedium>
            {title}
          </InspectorTooltipStyles.StyledDescriptionMedium>
        ) : (
          title
        )}
        {typeof children === "string" ? (
          <InspectorTooltipStyles.StyledDescription>
            {children}
          </InspectorTooltipStyles.StyledDescription>
        ) : (
          children
        )}
      </>
    </InspectorTooltipStyles.StyledPopover>
  );
};

export { InspectorTooltipStyles };

export default InspectorTooltip;
