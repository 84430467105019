import React, { ReactElement } from "react";
import { ISingleProps, IItem } from "@diana-ui/dropdown";
import * as TextDropdownStyles from "./TextDropdown.style";

const ICON_SIZE_PX = 10;

const TextDropdown: React.FC<ISingleProps<IItem>> = ({
  disabled,
  items,
  placeholder = "\u00A0", // non blocking space to force header line height
  selectedItem,
  text,
  ref,
  ...props
}) => {
  const isDisabled = disabled || items.length === 0;

  const renderHeader = (isOpen: boolean): ReactElement => (
    <TextDropdownStyles.TextWrapper
      className={[isOpen && "open", isDisabled && "disabled"]
        .filter(Boolean)
        .join(" ")}
    >
      <TextDropdownStyles.Text>
        {text ?? (selectedItem ? selectedItem.text : placeholder)}
      </TextDropdownStyles.Text>
      <TextDropdownStyles.StyledIcon
        className={[isOpen && "open", isDisabled && "disabled"]
          .filter(Boolean)
          .join(" ")}
        name="ChevronDown"
        size={ICON_SIZE_PX}
      />
    </TextDropdownStyles.TextWrapper>
  );

  return (
    <TextDropdownStyles.StyledDropdown
      disabled={isDisabled}
      selectedItem={selectedItem}
      items={items}
      renderHeader={renderHeader}
      {...(props as any)}
    />
  );
};

export { TextDropdownStyles };

export default TextDropdown;
