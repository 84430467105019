import { Icon } from "@ddm-design-system/icon";
import React from "react";
import styled, { css } from "styled-components";
import { dotsStyle } from "./Carousel.style";

export const CarouselWrapper = styled.div(
  ({ theme }) => css`
    position: relative;
    padding: 0 ${theme.spaceUnit.lg};
  `
);

export const CustomCarouselDotsWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -${theme.spaceUnit.xl};
  `
);

export const CustomCarouselDots = styled.div<{ selected: boolean }>(
  ({ theme, selected }) => css`
    ${dotsStyle};
    cursor: initial;
    :hover:active {
      border-color: ${theme.colors.grey.grey50};
      background-color: ${theme.colors.grey.grey50};
    }

    ${selected &&
    css`
      background-color: ${theme.colors.grey.grey100};
      border-color: ${theme.colors.grey.grey100};
    `}
  `
);

const arrowsStyle = css`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    height: 22px;
    width: 22px;
    background-color: ${theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
  `}
`;

export const CustomArrowLeft = styled.div.attrs({
  children: <Icon name="ChevronLeft" />
})(
  () => css`
    ${arrowsStyle};
    left: 0;
  `
);

export const CustomArrowRight = styled.div.attrs({
  children: <Icon name="ChevronRight" />
})(
  () => css`
    ${arrowsStyle};
    right: 0;
  `
);
