import React from "react";
import { ITextInputProps } from "./TextInput";
import * as ErrorTextInputStyles from "./ErrorTextInput.style";

export interface IErrorTextInputProps extends ITextInputProps {
  error?: string | boolean;
  hint?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CustomTextInputComponent?: any;
}

const ErrorTextInput: React.FC<IErrorTextInputProps> = ({
  CustomTextInputComponent,
  className,
  error,
  hint,
  ref,
  ...props
}) => (
  <ErrorTextInputStyles.ErrorTextInputWrapper className={className}>
    <ErrorTextInputStyles.StyledBaseTextInput
      as={CustomTextInputComponent}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(props as any)}
      hasError={typeof error === "boolean" ? error : error !== null && error !== undefined}
    />
    <ErrorTextInputStyles.HelperLabel>
      {error && typeof error === "string" ? (
        <ErrorTextInputStyles.TypedLabel error>{error}</ErrorTextInputStyles.TypedLabel>
      ) : hint ? (
        <ErrorTextInputStyles.TypedLabel hint>{hint}</ErrorTextInputStyles.TypedLabel>
      ) : (
        <ErrorTextInputStyles.TypedLabel>&nbsp;</ErrorTextInputStyles.TypedLabel>
      )}
    </ErrorTextInputStyles.HelperLabel>
  </ErrorTextInputStyles.ErrorTextInputWrapper>
);

ErrorTextInput.displayName = "ErrorTextInput";

export { ErrorTextInputStyles };

export default ErrorTextInput;
