import styled, { css } from "styled-components";

/**
 * Component's wrapper component
 */
export const Section = styled.section(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.grey.grey25};
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  `
);
