import styled, { css } from "styled-components";
import { Body, BodyHighlight } from "@ddm-design-system/typography";
import {
  ExpandablePanel as BaseExpandablePanel,
  ExpandablePanelStyles as BaseExpandablePanelStyles
} from "@diana-ui/expandable-panel";

export const StyledBaseExandablePanel = styled(BaseExpandablePanel)(
  ({ theme, disabled }) => css`
    border-bottom: 1px solid ${theme.colors.grey.grey50};
    border-top: 1px solid ${theme.colors.grey.grey50};

    ${BaseExpandablePanelStyles.Body} {
      @media (max-width: 900px) {
        padding: ${theme.spaceUnit.lg} 0px;
      }
    }

    ${BaseExpandablePanelStyles.Header} {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${theme.spaceUnit.xl} ${theme.spaceUnit.lg};

      @media (max-width: 900px) {
        align-items: flex-start;
        padding: ${theme.spaceUnit.lg} 0px;
      }

      ${disabled &&
      css`
        cursor: default;
      `}
    }

    ${BaseExpandablePanelStyles.HeaderIcon} {
      align-self: flex-start;
      flex-shrink: 1;
      //padding-top: ${theme.spaceUnit.sm};
      transition: all 0.5s;
      transform: rotateZ(0deg);

      @media (max-width: 900px) {
        flex-shrink: 0;
      }

      ${disabled &&
      css`
        display: none;
      `}
    }

    &.expanded {
      ${BaseExpandablePanelStyles.HeaderIcon} {
        // padding-bottom: ${theme.spaceUnit.sm};
        padding-top: 0px;
        transform: rotateZ(-180deg);
      }
    }
  `
);

export const Header = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: calc(100% - ${theme.spaceUnit.lg} - ${theme.spaceUnit.md});
  `
);

export const HeaderBody = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    width: 100%;
    padding-right: ${theme.spaceUnit.lg};

    @media (max-width: 900px) {
      align-items: flex-start;
      flex-direction: column;
      padding-right: ${theme.spaceUnit.xs};
    }
  `
);

export const HeaderTitleWrapper = styled.span(
  () => css`
    display: flex;
    overflow: hidden;

    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      text-align: start;
    }
  `
);

export const StyledHeaderTitle = styled(BodyHighlight)(
  ({ theme }) => css`
    margin-right: ${theme.spaceUnit.xs};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `
);

export const StyledSubitle = styled(Body)(
  ({ theme }) => css`
    color: ${theme.colors.grey.grey100};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: 900px) {
      margin-top: ${theme.spaceUnit.xs};
      overflow: initial;
      white-space: initial;
    }
  `
);
