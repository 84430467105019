import styled, { css } from "styled-components";
import {
  ExpandablePanels as BaseExpandablePanels,
  ExpandablePanelsStyles as BaseExpandablePanelsStyles
} from "@diana-ui/expandable-panel";
import { ExpandablePanelStyles } from "./ExpandablePanel";

export { BaseExpandablePanelsStyles };

export default styled(BaseExpandablePanels)(
  () => css`
    > ${ExpandablePanelStyles.StyledBaseExandablePanel} {
      border-top: 0;
    }

    > * > ${ExpandablePanelStyles.StyledBaseExandablePanel} {
      border-top: 0;
    }
  `
);
