import {
  CardBody as BaseCardBody,
  CardBodyStyles as BaseCardBodyStyles
} from "@diana-ui/card";
import styled, { css } from "styled-components";

const CardBody = styled(BaseCardBody)(
  ({ theme }) => css`
    padding: ${theme.spaceUnit.lg};
  `
);

export { BaseCardBodyStyles };

export default CardBody;
