import styled, { css } from "styled-components";
import { Carousel as BaseCarousel } from "@diana-ui/carousel";
import type { ICarouselProps, ICarouselRef } from "@diana-ui/carousel";

const Carousel = styled(BaseCarousel).attrs<ICarouselProps>(
  ({ customLeftArrow, customRightArrow }) => ({
    customLeftArrow: customLeftArrow || "ChevronLeft",
    customRightArrow: customRightArrow || "ChevronRight",
    renderArrowsWhenDisabled: true
  })
)<ICarouselProps>(() => css``);

Carousel.displayName = "Carousel";

export type { ICarouselProps, ICarouselRef };

export default Carousel;
