import styled, { css } from "styled-components";
import { SecondaryButton } from "@ddm-design-system/button";
import { Divider } from "@ddm-design-system/divider";
import {
  CardFooter as BaseCardFooter,
  CardFooterStyles as BaseCardFooterStyles
} from "@diana-ui/card";

export const DividerWrapper = styled.div(
  ({ theme }) => css`
    padding: 0px ${theme.spaceUnit.lg};
  `
);

export const StyledDivider = styled(Divider)``;

export const StyledSecondaryButton = styled(SecondaryButton)``;

export const StyledCardFooter = styled(BaseCardFooter)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spaceUnit.lg};
  `
);

export { BaseCardFooterStyles };
