import styled, { css } from "styled-components";
import { Stepper as BaseStepper, StepperStyles } from "@diana-ui/stepper";

const Stepper = styled(BaseStepper)(
  ({ theme }) => css`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &.clickable {
      ${StepperStyles.Step} {
        cursor: pointer;
      }
    }

    ${StepperStyles.StepWrapper} {
      display: flex;
      flex: 1;
      align-items: center;

      &:first-child {
        flex: 0;
      }

      &.active {
        ${StepperStyles.InnerStep} {
          background-color: ${theme.colors.black};
        }
      }
    }

    ${StepperStyles.Step} {
      box-sizing: border-box;
      min-width: 12px;
      min-height: 12px;
      border-radius: 50%;
      background-color: ${theme.colors.grey.greenish};
      padding: 3px;
      display: flex;
    }

    ${StepperStyles.InnerStep} {
      box-sizing: border-box;
      flex: 1;
      border-radius: 50%;
    }

    ${StepperStyles.Line} {
      z-index: -1;
      box-sizing: border-box;
      width: 100%;
      height: 2px;
      background-color: ${theme.colors.grey.greenish};
    }
  `
);

export { StepperStyles };

export default Stepper;
