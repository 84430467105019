import React from "react";
import { DefaultTheme, useTheme } from "styled-components";
import * as d3 from "d3";
import BarChart, { IProps as IBarChartProps } from "./BarChart";

export interface IProps extends IBarChartProps {
  avg: number;
}

const BarChartAvg: React.FC<IProps> = ({ avg, data, maxY, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme = useTheme() as DefaultTheme | any;

  const maxDataValue = Math.max(
    ...data.map(dataPoint =>
      Array.isArray(dataPoint.value) ? d3.sum(dataPoint.value) : dataPoint.value
    )
  );
  const customDataStyles = {
    fill: theme.colors.white,
    stroke: theme.colors.black,
    strokeWidth: 3
  };
  const customData = [
    ...data.slice(0, data.length - 1),
    {
      ...data[data.length - 1],
      customStyles: data[data.length - 1]?.customStyles || customDataStyles
    }
  ];
  const lineData = [
    { label: data[0].label, value: avg },
    { label: data[data.length - 1].label, value: avg }
  ];

  return (
    <BarChart
      {...props}
      data={customData}
      drawLineDataCircles={false}
      lineData={lineData}
      // maxY is by default 110% bigger than maxDataValue to allow space for bar value text
      maxY={maxY ?? maxDataValue * 1.1}
      showYAxis={false}
    />
  );
};

export default BarChartAvg;
