import styled, { css } from "styled-components";
import { Popover, PopoverStyles } from "@ddm-design-system/popover";
import { Icon } from "@ddm-design-system/icon";
import { Body } from "@ddm-design-system/typography";

export const StyledPopover = styled(Popover)(
  ({ theme }) => css`
    ${PopoverStyles.HeaderWrapper} {
      display: flex;
      align-items: center;
    }

    ${PopoverStyles.StyledPopover} {
      display: flex;
      align-items: center;
      background-color: ${theme.colors.black};
      border-radius: 4px;
      color: ${theme.colors.white};
      padding: ${theme.spaceUnit.xs} ${theme.spaceUnit.md};
      transform: translateY(-10px);

      ::after {
        background-color: ${theme.colors.black};
        content: "";
        height: ${theme.spaceUnit.xs};
        position: absolute;
        transform: rotate(45deg);
        width: ${theme.spaceUnit.xs};
      }

      &.top {
        ::after {
          bottom: -4px;
          left: calc(50% - ${theme.spaceUnit.xxs});
        }
      }

      &.bottom {
        ::after {
          top: -4px;
          left: calc(50% - ${theme.spaceUnit.xxs});
        }
      }

      &.left {
        ::after {
          right: -4px;
          top: calc(50% - ${theme.spaceUnit.xxs});
        }
      }

      &.right {
        ::after {
          left: -4px;
          top: calc(50% - ${theme.spaceUnit.xxs});
        }
      }
    }
  `
);

export const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    margin-right: ${theme.spaceUnit.xs};
  `
);

export const StyledBody = styled(Body)``;
