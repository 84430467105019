import React, { forwardRef, ForwardRefRenderFunction } from "react";
import {
  CustomCarouselDots,
  CarouselWrapper,
  CustomArrowLeft,
  CustomArrowRight,
  CustomCarouselDotsWrapper
} from "./CustomCarousel.style";
import { Carousel, ICarouselProps, ICarouselRef } from ".";

export interface ICustomCarousel {
  itemsLength: number;
}

export type ICustomCarouselProps = ICustomCarousel & ICarouselProps;

const CustomCarousel: ForwardRefRenderFunction<
  ICarouselRef,
  ICustomCarouselProps
> = ({ children, itemsLength, arrows = true, ...carouselProps }, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const DotsGroup = ({ next, previous, goToSlide, ...rest }: any) => {
    const {
      carouselState: { currentSlide }
    } = rest;

    let itemsPerDot = 1;
    let nrDots = itemsLength;

    if (itemsLength > 5) {
      itemsPerDot = Math.round(itemsLength / 5);
      nrDots = 5;
    }

    return (
      <>
        {arrows && (
          <>
            <CustomArrowLeft onClick={previous} />
            <CustomArrowRight onClick={next} />
          </>
        )}
        <CustomCarouselDotsWrapper>
          {Array(nrDots)
            .fill(null)
            .map((_: unknown, index: number) => {
              return (
                <CustomCarouselDots
                  key={index}
                  selected={
                    currentSlide >= itemsPerDot * index &&
                    currentSlide < itemsPerDot * (index + 1)
                  }
                />
              );
            })}
        </CustomCarouselDotsWrapper>
      </>
    );
  };

  return (
    <CarouselWrapper>
      <Carousel
        {...carouselProps}
        arrows={false}
        ref={ref}
        renderButtonGroupOutside
        customButtonGroup={<DotsGroup />}
      >
        {children}
      </Carousel>
    </CarouselWrapper>
  );
};

export default forwardRef(CustomCarousel);
