import React from "react";
import {
  ICardFooterProps,
  CardFooterStyles as BaseCardFooterStyles
} from "@diana-ui/card";
import * as CardFooterStyles from "./CardFooter.style";

export interface IProps {
  link?: string;
  showDivider?: boolean;
  onLinkClick?: () => void;
}

// TODO Replace SecondaryButton with Link when it is implemented
export const CardFooter: React.FC<IProps & ICardFooterProps> = ({
  link,
  children,
  showDivider = true,
  onLinkClick,
  ref,
  ...props
}) => {
  return (
    <>
      {showDivider && (
        <CardFooterStyles.DividerWrapper>
          <CardFooterStyles.StyledDivider />
        </CardFooterStyles.DividerWrapper>
      )}
      <CardFooterStyles.StyledCardFooter {...props}>
        {link ? (
          <CardFooterStyles.StyledSecondaryButton onClick={onLinkClick}>
            {link}
          </CardFooterStyles.StyledSecondaryButton>
        ) : (
          children
        )}
      </CardFooterStyles.StyledCardFooter>
    </>
  );
};

export { CardFooterStyles, BaseCardFooterStyles };

export default CardFooter;
