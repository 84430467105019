import React, { useMemo, useRef, useState } from "react";
import { DefaultTheme, useTheme } from "styled-components";
import { ParentSize } from "@vx/responsive";
import { AxisLeft } from "@vx/axis";
import { scaleBand, scaleLinear } from "@vx/scale";
import { Bar, LinePath, Circle } from "@vx/shape";
import { GridRows } from "@vx/grid";
import { Text } from "@vx/text";
import { localPoint } from "@vx/event";
import * as d3 from "d3";
import { ScaleLinear, ScaleBand } from "d3";
import Tooltip, { ITooltipValue } from "./Tooltip";
import { BottomAxis, ITick } from "./Axis";

const DEFAULT_INNER_PADDING = 0.2;
const DEFAULT_OUTER_PADDING = 0.2;
const DEFAULT_NUMBER_OF_TICKS = 4;
const DEFAULT_MARGIN = { left: 60, right: 0, top: 0, bottom: 20 };
const BAR_VALUE_MARGIN_PX = 16;

export interface IChartData {
  customStyles?: { [styleKey: string]: string | number };
  label: string;
  tickGroup?: string;
  tooltipLabel?: string;
}

export interface IBarData extends IChartData {
  value: number | number[];
}
export interface ILineData extends IChartData {
  value: number;
}

interface IMargin {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
}
export interface IProps {
  domain: string[];
  height?: number;
  data: IBarData[];
  lineData?: ILineData[];
  maxY?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelTranslate?: (scale: any) => number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tickFormat?: (d: any, i: number) => string;
  barBorderRadius?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tickYFormat?: (d: any, i: number) => string;
  hideSeparators?: boolean;
  paddingInner?: number;
  paddingOutter?: number;
  numTicks?: number;
  margin?: IMargin;
  drawLineDataCircles?: boolean;
  lineDataLabel?: string; // TODO improve this by extracting line data to a different component

  sliceAxis?: number;
  showYAxis?: boolean;

  onTooltipChange?: (
    x: number | null,
    y: number | null,
    val: ITooltipValue | null,
    xMax: number,
    yMax: number
  ) => void;
}

function bar(x: number, y: number, w: number, h: number, r: number, f = 1) {
  // x coordinates of top of arcs
  const x0 = x + r;
  const x1 = x + w - r;
  // y coordinates of bottom of arcs
  const y0 = y - h + r;

  // assemble path:
  return (
    `M ${x} ${y}` +
    `L ${x} ${y0}` +
    `A ${r} ${r} 0 0 ${f} ${x0} ${y - h}` +
    `L ${x1} ${y - h}` +
    `A ${r} ${r} 0 0 ${f} ${x + w} ${y0}` +
    `L ${x + w} ${y}` +
    "Z"
  );
}

let elapsed = 0;
let lastMove = 0;

const BarChartInner: React.FC<IProps> = ({
  domain,
  data,
  lineData,
  maxY,
  tickFormat,
  tickYFormat,
  labelTranslate,
  height,
  onTooltipChange,
  paddingInner,
  paddingOutter,
  numTicks,
  hideSeparators,
  sliceAxis,
  margin,
  drawLineDataCircles = true,
  showYAxis = true,
  lineDataLabel
}) => {
  const containerMargin = { ...DEFAULT_MARGIN, ...(margin || {}) };
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-explicit-any
  const theme = useTheme() as DefaultTheme | any;

  const colorScale = [
    theme.colors.black,
    theme.colors.grey.grey100,
    theme.colors.grey.grey50,
    theme.colors.grey.grey25
  ];

  const [hoverBar, setHoverBar] = useState<string>();

  let tickValues: ITick[];
  if (data.some(d => d.tickGroup)) {
    const tickMap: {
      [key: string]: IChartData[];
    } = {};
    data.forEach((d2: IBarData) => {
      if (!d2.tickGroup) {
        return;
      }
      if (tickMap[d2.tickGroup]) {
        tickMap[d2.tickGroup].push(d2);
      } else {
        tickMap[d2.tickGroup] = [d2];
      }
    });
    tickValues = [];
    Object.keys(tickMap).forEach(tick => {
      tickValues.push({
        xPosition: tickMap[tick][Math.floor(tickMap[tick].length / 2)].label,
        label: tick,
        startPosition: tickMap[tick][0].label
      });
    });
  }
  const maxValue: number = useMemo(() => {
    return (
      d3.max([
        d3.max(data, (d: IBarData) =>
          Array.isArray(d.value) ? d3.sum(d.value) : d.value
        ) || 1,
        d3.max(lineData || [], (d: ILineData) => d.value) || 1
      ]) || 1
    );
  }, [data, lineData]);

  return (
    <ParentSize>
      {parent => {
        const containerHeight = height || 100;
        const containerWidth = parent.width;
        const yMax =
          containerHeight - containerMargin.top - containerMargin.bottom;
        const xMax =
          containerWidth - containerMargin.left - containerMargin.right;

        const xScale: ScaleBand<string> = scaleBand({
          domain,
          range: [containerMargin.left, xMax + containerMargin.left],
          paddingInner: paddingInner ?? DEFAULT_INNER_PADDING,
          paddingOuter: paddingOutter ?? DEFAULT_OUTER_PADDING
        });
        const yScale: ScaleLinear<number, number> = scaleLinear({
          rangeRound: [yMax + containerMargin.top, containerMargin.top],
          domain: [0, maxY || maxValue]
        });
        const barWidth = Math.min(xScale.bandwidth(), 24);

        const barHeights: number[][] = data.map(d =>
          (Array.isArray(d.value) ? d.value : [d.value]).map(
            (val: number) => yMax + containerMargin.top - (yScale(val) || 0)
          )
        );

        const y1Array: number[][] = barHeights.map(d =>
          d.map(
            (y, i) => yMax + containerMargin.top - d3.sum(d.slice(0, i)) - y
          )
        );

        const y1ArrayMax: number[] = barHeights.map(
          d => yMax + containerMargin.top - d3.sum(d)
        );

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleMouseOver = (event: any) => {
          elapsed = Date.now() - lastMove;
          if (elapsed < 40) {
            return;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const coords: any = localPoint(event.target.ownerSVGElement, event);
          const eachBand = xScale.step();
          const x =
            coords.x -
            containerMargin.left -
            (xScale.step() * DEFAULT_OUTER_PADDING) / 2;
          const index = Math.floor(x / eachBand);
          const val = xScale.domain()[index];
          lastMove = Date.now();
          if (onTooltipChange && val) {
            const dataPoint = data.find(d => d.label === val);
            onTooltipChange(
              coords.x,
              coords.y,
              {
                label: dataPoint?.tooltipLabel || dataPoint?.label || "",
                myValue: dataPoint?.value,
                vsValue: lineData && lineData.find(d => d.label === val)?.value
              },
              xMax,
              yMax
            );
          }

          setHoverBar(val);
        };
        const handleMouseOut = () => {
          if (onTooltipChange) {
            onTooltipChange(null, null, null, xMax, yMax);
          }
          setHoverBar(undefined);
        };
        return (
          <svg height={containerHeight} width={containerWidth}>
            <GridRows
              scale={yScale}
              width={xMax}
              height={yMax}
              left={containerMargin.left}
              numTicks={numTicks ?? DEFAULT_NUMBER_OF_TICKS}
            />
            <g>
              {data &&
                data.map((d: IBarData, i) => {
                  // if strokeWidth is passed, the bar's height and width need to take it into account
                  const strokeSizeOffset =
                    Number(d.customStyles?.strokeWidth ?? 0) / 2;

                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={`bar-${d.label}-${i}`}>
                      <Bar
                        x={
                          (xScale(d.label) || 0) -
                          (xScale.step() * DEFAULT_INNER_PADDING) / 2
                        }
                        fill={
                          hoverBar && d.label === hoverBar
                            ? theme.colors.grey.grey25
                            : "none"
                        }
                        key={`bar-background-${d.label}`}
                        y={containerMargin.top}
                        height={yMax}
                        width={
                          xScale.bandwidth() +
                          xScale.step() * DEFAULT_INNER_PADDING
                        }
                      />
                      {!showYAxis && (
                        <Text
                          x={(xScale(d.label) || 0) + xScale.bandwidth() / 2}
                          y={y1ArrayMax[i] - BAR_VALUE_MARGIN_PX}
                          fontFamily={theme.fontFamily}
                          fontSize={theme.typography.description.fontSize}
                          lineHeight={theme.typography.description.lineHeight}
                          textAnchor="middle"
                        >
                          {Array.isArray(d.value) ? d3.sum(d.value) : d.value}
                        </Text>
                      )}
                      {d3.sum(barHeights[i]) > 0 &&
                        barHeights[i].map((b, j) => {
                          return (
                            <path
                              // eslint-disable-next-line react/no-array-index-key
                              key={`bar-fill-${d.label}-${j}`}
                              d={bar(
                                (xScale(d.label) || 0) +
                                  xScale.bandwidth() / 2 -
                                  barWidth / 2 +
                                  strokeSizeOffset / 2,
                                b + y1Array[i][j],
                                barWidth - strokeSizeOffset,
                                b - strokeSizeOffset,
                                y1Array[i][j] === y1ArrayMax[i] && b !== 0
                                  ? barWidth / 8
                                  : 0
                              )}
                              {...d.customStyles}
                              fill={
                                d.customStyles?.fill
                                  ? `${d.customStyles?.fill}`
                                  : barHeights[i].length === 1
                                  ? theme.colors.black
                                  : colorScale
                                      .slice(0, barHeights[i].length)
                                      .reverse()[j]
                              }
                            />
                          );
                        })}
                    </React.Fragment>
                  );
                })}
              {lineData && (
                <LinePath
                  pathLength={100}
                  data={lineData}
                  x={(d, index) => {
                    if (drawLineDataCircles) {
                      return (xScale(d.label) || 0) + xScale.bandwidth() / 2;
                    }

                    // eslint-disable-next-line no-nested-ternary
                    return index === 0
                      ? 0
                      : index === lineData.length - 1
                      ? containerWidth
                      : (xScale(d.label) || 0) + xScale.bandwidth() / 2;
                  }}
                  y={d => yScale(d.value) || 0}
                  stroke={theme.colors.warning.warning100}
                  strokeWidth={2}
                />
              )}
              {lineData &&
                lineData.map(
                  d =>
                    drawLineDataCircles && (
                      <Circle
                        key={`dot-${d.label}`}
                        cx={(xScale(d.label) || 0) + xScale.bandwidth() / 2}
                        cy={yScale(d.value)}
                        r={4}
                        fill={theme.colors.warning.warning100}
                      />
                    )
                )}
              {lineData && lineDataLabel && (
                <>
                  <Text
                    x={containerWidth}
                    y={lineData && (yScale(lineData[0].value) || 0) - 5}
                    fill={theme.colors.warning.warning100}
                    fontFamily={theme.fontFamily}
                    fontSize={theme.typography.description.fontSize}
                    lineHeight={theme.typography.description.lineHeight}
                    textAnchor="end"
                  >
                    {lineData?.[0].value}
                  </Text>
                  <Text
                    x={containerWidth}
                    y={lineData && (yScale(lineData[0].value) || 0) + 15}
                    fill={theme.colors.warning.warning100}
                    fontFamily={theme.fontFamily}
                    fontSize={theme.typography.description.fontSize}
                    lineHeight={theme.typography.description.lineHeight}
                    textAnchor="end"
                  >
                    {lineDataLabel}
                  </Text>
                </>
              )}
              &gt;
              <BottomAxis
                tickWidth={
                  xScale.bandwidth() + xScale.step() * DEFAULT_INNER_PADDING
                }
                tickFormat={tickFormat}
                scale={xScale}
                top={yMax + containerMargin.top}
                ticks={tickValues}
                sliceAxis={sliceAxis}
                separators={
                  hideSeparators
                    ? undefined
                    : tickValues &&
                      tickValues.map(d => ({
                        xPosition: d.startPosition || ""
                      }))
                }
                translate={labelTranslate}
              />
            </g>
            <rect
              x={0}
              width={containerMargin.left}
              y={0}
              height={height}
              fill={theme.colors.white}
            />
            <rect
              x={containerMargin.left}
              y={0}
              height={height}
              fill="transparent"
              width={xMax > 0 ? xMax : 0}
              onTouchMove={e => handleMouseOver(e)}
              onMouseMove={e => handleMouseOver(e)}
              onMouseLeave={() => handleMouseOut()}
              onTouchEnd={() => handleMouseOut()}
            />
            {showYAxis && (
              <AxisLeft
                scale={yScale}
                top={0}
                tickFormat={tickYFormat}
                left={containerMargin.left}
                hideAxisLine
                tickLength={9}
                hideTicks
                numTicks={4}
                tickLabelProps={() => ({
                  ...theme.typography.label,
                  fill: theme.colors.grey.grey100,
                  stroke: theme.colors.grey.grey100,
                  textAnchor: "end"
                })}
              />
            )}
          </svg>
        );
      }}
    </ParentSize>
  );
};

interface IBarChartProps {
  tooltipElement?: React.FC<{ value?: ITooltipValue | null }>;
  variableYTooltip?: boolean;
  tooltipYOffset?: number;
}
export const BarChart: React.FC<IBarChartProps & IProps> = props => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref: any = useRef(null);
  const [value, setValue] = useState<ITooltipValue | null>();
  return (
    <div style={{ position: "relative" }}>
      <BarChartInner
        {...props}
        onTooltipChange={(x, y, v, xMax, yMax) => {
          if (!x) {
            setValue(null);
          } else if (ref && ref.current) {
            let boundedX = x;
            const containerMargin = {
              ...DEFAULT_MARGIN,
              ...(props.margin || {})
            };
            const tooltipWidth = ref.current.clientWidth || 200;
            if (boundedX < containerMargin.left + tooltipWidth / 2) {
              boundedX = containerMargin.left + tooltipWidth / 2;
            }
            if (boundedX > xMax + containerMargin.left - tooltipWidth / 2) {
              boundedX = xMax + containerMargin.left - tooltipWidth / 2;
            }
            if (props.variableYTooltip && y !== null) {
              let boundedY = y;
              const tooltipHeight = ref.current.clientHeight || 200;
              if (boundedY < containerMargin.top) {
                boundedY = containerMargin.top;
              }
              if (boundedY > yMax + containerMargin.top - tooltipHeight) {
                boundedY = yMax + containerMargin.top - tooltipHeight;
              }
              ref.current.style.top = `${boundedY}px`;
            }
            ref.current.style.left = `${boundedX}px`;
            setValue(v);
          }
        }}
      />
      {props.tooltipElement && (
        <Tooltip
          wrappedRef={ref}
          yOffset={props.tooltipYOffset}
          value={value}
          tooltipInner={props.tooltipElement}
        />
      )}
    </div>
  );
};
export default BarChart;
