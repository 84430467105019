import styled, { css } from "styled-components";
import { Description, DescriptionMedium } from "@ddm-design-system/typography";
import { Popover, PopoverStyles } from "@ddm-design-system/popover";

export const StyledDescriptionMedium = styled(DescriptionMedium)(
  ({ theme }) => css`
    margin-bottom: ${theme.spaceUnit.xs};
    text-align: center;
  `
);

export const StyledDescription = styled(Description)``;

export const StyledPopover = styled(Popover)(
  ({ theme }) => css`
    ${PopoverStyles.StyledPopover} {
      background-color: ${theme.colors.white};
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      margin-top: ${theme.spaceUnit.xs};
      max-width: 250px;
      padding: ${theme.spaceUnit.sm};
    }

    ${PopoverStyles.HeaderWrapper} {
      display: flex;
      align-items: center;
    }
  `
);
