import React, { RefObject } from "react";
import styled, { css } from "styled-components";

export const StyledTooltip = styled.div<{
  styleProps: { value?: ITooltipValue | null; yOffset?: number };
}>(
  ({ theme, styleProps: { value, yOffset } }) => css`
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: absolute;
    top: -10px;
    font-family: ${theme.fontFamily};
    transform: translate(-50%);
    pointer-events: none;
    opacity: ${value ? 1 : 0};
    top: ${yOffset !== undefined ? yOffset : -10}px;
  `
);

export interface ITooltipValue {
  label: string;
  myValue?: number | number[] | null;
  vsValue?: number | null;
}
interface ITooltipOwnProps {
  value?: ITooltipValue | null;
  tooltipInner?: React.FC<{ value?: ITooltipValue | null }>;
  yOffset?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  wrappedRef?: any;
}

export type ITooltipProps = ITooltipOwnProps & JSX.IntrinsicElements["div"];

const Tooltip: React.FC<ITooltipProps> = ({
  tooltipInner,
  value,
  yOffset,
  wrappedRef
}) => {
  const TooltipContent = tooltipInner || (() => <div />);

  return (
    <StyledTooltip
      ref={wrappedRef as RefObject<HTMLDivElement>}
      styleProps={{
        value,
        yOffset
      }}
    >
      {tooltipInner && <TooltipContent value={value} />}
    </StyledTooltip>
  );
};

export default Tooltip;
