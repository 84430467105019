import React, { useEffect, useState } from "react";
import * as KegStyles from "./Keg.style";

const DEFAULT_WARNING_THRESHOLD_PCT = 15;
const MAX_KEG_VOLUME = 20;

export interface IProps {
  emptyLabel?: string;
  hasError?: boolean;
  hasWarning?: boolean;
  logoFallback?: { color: string; initials: string };
  logoUrl?: string;
  maxVolume?: number;
  volumeLeft: number | null;
  warningThresholdPct?: number;
  noVolume?: boolean;
  decimalCases?: number;
}

export type IKegProps = IProps & JSX.IntrinsicElements["div"];

const Keg: React.FC<IKegProps> = ({
  className,
  emptyLabel,
  hasError = false,
  hasWarning = false,
  logoFallback,
  logoUrl,
  maxVolume = MAX_KEG_VOLUME,
  volumeLeft,
  warningThresholdPct = DEFAULT_WARNING_THRESHOLD_PCT,
  noVolume,
  decimalCases = 0
}) => {
  const [heightTimeout, setHeightTimeout] = useState(false);
  const [useFallback, setUseFallback] = useState(false);
  const volumeLeftPct = volumeLeft ? (volumeLeft / maxVolume) * 100 : 0;
  const isBelowThreshold = volumeLeftPct <= warningThresholdPct;
  const height = noVolume
    ? 100
    : hasError || !heightTimeout
    ? 0
    : volumeLeftPct;
  const label = hasError
    ? "--"
    : volumeLeft === 0
    ? emptyLabel
    : `${(volumeLeft || 0).toFixed(decimalCases)}L`;

  // delay setting the beverage's height for the css transition to take effect
  useEffect(() => {
    setTimeout(() => {
      setHeightTimeout(true);
    }, 0);
  }, []);

  return (
    <KegStyles.StyledKegWrapper
      className={[
        className,
        maxVolume > MAX_KEG_VOLUME && "tank",
        noVolume && "no-volume",
        hasWarning && "warning",
        hasError && "error",
        isBelowThreshold &&
          (volumeLeft || 0) > 0 &&
          !hasError &&
          !hasWarning &&
          "below-threshold"
      ]
        .filter(Boolean)
        .join(" ")}
    >
      {!noVolume && (
        <KegStyles.StyledKegLabel>
          <KegStyles.StyledSectionTitle>{label}</KegStyles.StyledSectionTitle>
        </KegStyles.StyledKegLabel>
      )}
      {logoUrl && !useFallback ? (
        <KegStyles.StyledLogoImg
          src={logoUrl}
          alt="Beer brand"
          onError={() => setUseFallback(true)}
        />
      ) : (
        <KegStyles.StyledBody logoFallback={logoFallback}>
          {logoFallback?.initials}
        </KegStyles.StyledBody>
      )}
      <KegStyles.StyledBeverageWrapper>
        <KegStyles.StyledBeverage height={height} />
      </KegStyles.StyledBeverageWrapper>
    </KegStyles.StyledKegWrapper>
  );
};

Keg.displayName = "Keg";

export { KegStyles };

export default Keg;
