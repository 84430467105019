import React from "react";
import { AxisBottom } from "@vx/axis";
import { Group } from "@vx/group";
import { useWindowSize } from "@ddm-design-system/hooks";
import { ScaleBand } from "d3";
import styled, { css, DefaultTheme, useTheme } from "styled-components";

export interface ITick {
  label: string;
  xPosition: string;
  startPosition?: string;
}
export interface ISeparator {
  xPosition: string;
}
interface IAllAxisProps {
  scale: ScaleBand<string>;
  top: number;
  ticks?: ITick[];
  separators?: ISeparator[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translate?: (scale: any) => number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tickFormat?: (d: any, i: number) => string;
  tickWidth?: number;
  sliceAxis?: number;
  innerPadding?: number;
}

export const StyledAxisBottom = styled(AxisBottom)(
  ({ theme }) => css`
    .tick {
      font-family: ${theme.fontFamily};
      font-size: 24px;
      svg {
        margin: 24px;
      }
    }
  `
);

export const BottomAxis: React.FC<IAllAxisProps> = ({
  scale,
  separators,
  top,
  ticks,
  tickFormat,
  tickWidth,
  translate,
  sliceAxis,
  innerPadding
}) => {
  const [width] = useWindowSize();
  const isMobile = width <= 900;

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-explicit-any
  const theme = useTheme() as DefaultTheme | any;
  const left = translate ? translate(scale) : 0;

  // eslint-disable-next-line no-unneeded-ternary
  const tickFormatFunc =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tickFormat || ((d: any, i: number) => (ticks ? ticks[i].label : d));

  return (
    <>
      <StyledAxisBottom
        stroke="none"
        top={top}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        scale={scale as any}
        tickLength={isMobile ? 0 : 8}
        hideTicks
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        tickLabelProps={() => ({
          ...theme.typography.label,
          fill: theme.colors.grey.grey100,
          textAnchor: "middle",
          verticalAnchor: isMobile ? "start" : undefined,
          width:
            isMobile && typeof tickWidth === "number" ? tickWidth : undefined,
          lineHeight: isMobile ? 14 : undefined
        })}
        tickValues={
          sliceAxis
            ? ((ticks && ticks.map(d => d.xPosition)) || scale.domain()).filter(
                (f, i) => i % sliceAxis
              )
            : (ticks && ticks.map(d => d.xPosition)) || scale.domain()
        }
        tickFormat={tickFormatFunc}
        labelClassName="tick"
        tickClassName="tick"
        left={left}
      />
      {separators && (
        <Group>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {separators.map((sep: any) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const x: any = scale(sep.xPosition);
            return (
              <line
                x1={x - (scale.step() * (innerPadding ?? 0.2)) / 2}
                y1={top}
                x2={x - (scale.step() * (innerPadding ?? 0.2)) / 2}
                y2={top + 30}
                stroke="#ccc"
                strokeWidth="2"
                strokeDasharray="3 3"
              />
            );
          })}
        </Group>
      )}
    </>
  );
};
