import React from "react";
import {
  ICardHeaderProps,
  CardHeaderStyles as BaseCardHeaderStyles
} from "@diana-ui/card";
import { IconNames } from "@ddm-design-system/icon";
import * as CardHeaderStyles from "./CardHeader.style";

export interface IProps {
  titleIcon?: IconNames;
  showDivider?: boolean;
}

export const CardHeader: React.FC<IProps & ICardHeaderProps> = ({
  showDivider = true,
  titleIcon,
  title,
  ref,
  ...props
}) => {
  const titleWithIcon = titleIcon ? (
    <CardHeaderStyles.StyledSpan>
      <CardHeaderStyles.StyledIcon name={titleIcon} />
      <CardHeaderStyles.StyledSectionTitle>
        {title}
      </CardHeaderStyles.StyledSectionTitle>
    </CardHeaderStyles.StyledSpan>
  ) : (
    title
  );

  return (
    <>
      <CardHeaderStyles.StyledBaseCardHeader title={titleWithIcon} {...props} />
      {showDivider && (
        <CardHeaderStyles.DividerWrapper>
          <CardHeaderStyles.StyledDivider />
        </CardHeaderStyles.DividerWrapper>
      )}
    </>
  );
};

export { CardHeaderStyles, BaseCardHeaderStyles };

export default CardHeader;
