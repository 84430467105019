import styled, { css } from "styled-components";
import { Toggle as BaseToggle, ToggleStyles } from "@diana-ui/toggle";

const Toggle = styled(BaseToggle)(
  ({ theme }) => css`
    outline: none;

    :hover {
      :not(.disabled) ${ToggleStyles.Container} {
        background-color: ${theme.colors.grey.greenish};
      }

      :not(.disabled):not(.checked) {
        ${ToggleStyles.Container} {
          background-color: ${theme.colors.grey.greenish};
        }

        ${ToggleStyles.Thumb} {
          background-color: ${theme.colors.grey.grey50};
        }
      }
    }

    &.checked {
      ${ToggleStyles.Container} {
        border-color: ${theme.colors.black};
      }

      ${ToggleStyles.Thumb} {
        background-color: ${theme.colors.black};
        margin-left: ${theme.spaceUnit.sm};
      }
    }

    &.disabled {
      ${ToggleStyles.Container} {
        border-color: ${theme.colors.grey.grey50};
      }
      ${ToggleStyles.Thumb} {
        background-color: ${theme.colors.grey.grey100};
      }
    }

    ${ToggleStyles.Container} {
      outline: none;
      border-color: ${theme.colors.grey.grey50};
      background-color: ${theme.colors.white};
      width: 30px;
      padding: 2px;
      border-radius: 10px;
    }

    ${ToggleStyles.Thumb} {
      height: ${theme.spaceUnit.sm};
      width: ${theme.spaceUnit.sm};
      border-radius: 10px;
      background-color: ${theme.colors.grey.grey50};
    }
  `
);

export { ToggleStyles };

export default Toggle;
