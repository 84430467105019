/* eslint-disable @typescript-eslint/no-use-before-define */
import styled, { css } from "styled-components";

/**
 * Component wrapper element
 */
export const Wrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    outline-style: none;
    user-select: none;
    cursor: pointer;
  `
);

/**
 * Component container element
 */
export const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    outline-style: none;
    user-select: none;
    cursor: pointer;
    border: 1px solid;
    border-color: ${theme.colors.grey.grey50};
    width: 30px;
    box-sizing: border-box;
    padding: 2px;
    border-radius: 10px;

    ${Wrapper} & {
      .checked {
        border-color: ${theme.colors.black};
      }
      .disabled {
        border-color: ${theme.colors.grey.grey100};
      }
    }
  `
);

export const Thumb = styled.div(
  ({ theme }) => css`
    height: ${theme.spaceUnit.sm};
    width: ${theme.spaceUnit.sm};
    border-radius: 10px;
    background-color: ${theme.colors.grey.grey50};
    transition: 0.3s margin-left;
    margin-left: 0;

    ${Wrapper} & {
      .checked {
        background-color: ${theme.colors.black};
        margin-left: ${theme.spaceUnit.sm};
      }
      .disabled {
        background-color: ${theme.colors.grey.grey100};
      }
    }
  `
);

export const Input = styled.input(
  () => css`
    display: none;
  `
);

export const StyledLabel = styled.label(
  ({ theme }) => css`
    cursor: inherit;
    margin-left: ${theme.spaceUnit.xxs};
    ${theme.typography.description};
  `
);
