import styled, { css } from "styled-components";

/**
 * Component container element
 */
export const Container = styled.div(
  () => css`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `
);

/**
 * Component step wrapper
 */
export const StepWrapper = styled.div(
  () => css`
    display: flex;
    flex: 1;
    align-items: center;

    &:first-child {
      flex: 0;
    }
  `
);

export const Line = styled.div(
  () => css`
    box-sizing: border-box;
    width: 100%;
    height: 2px;
  `
);

export const Step = styled.div(
  () => css`
    box-sizing: border-box;
    min-width: 12px;
    min-height: 12px;
    border-radius: 50%;
    padding: 3px;
    display: flex;

    ${StepWrapper} & {
      &.clickable {
        cursor: pointer;
      }
    }
  `
);

export const InnerStep = styled.div(
  ({ theme }) => css`
    box-sizing: border-box;
    flex: 1;
    border-radius: 50%;

    ${StepWrapper} & {
      &.active {
        background-color: ${theme.colors.black};
      }
    }
  `
);
