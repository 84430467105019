import { createGlobalStyle, css } from "styled-components";

export const dotsStyle = css`
  ${({ theme }) => css`
    display: inline-block;
    opacity: 1;
    padding: 0;
    box-shadow: none;
    transition: none;
    border-style: solid;
    border-color: ${theme.colors.grey.grey50};
    padding: 0;
    margin: 0;
    margin-right: 6px;
    outline: 0;
    cursor: pointer;

    background-color: ${theme.colors.grey.grey50};
    border-radius: 50%;
    border-width: 1px;
    width: 6px;
    height: 6px;
    margin: ${theme.spaceUnit.xxs};

    :hover:active {
      background-color: ${theme.colors.grey.grey100};
      border-color: ${theme.colors.grey.grey100};
    }
  `}
`;

export const GlobalCarouselStyles = createGlobalStyle`
  ${({ theme }) => css`
    .react-multi-carousel-track {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      position: relative;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      will-change: transform, transition;
    }

    .react-multiple-carousel__arrow {
      position: absolute;
      outline: 0;
      transition: all 0.5s;
      border-radius: 35px;
      z-index: 1000;
      border: 0;
      opacity: 1;
      cursor: pointer;
      background: none;
      height: 16px;
      width: 16px;
      min-height: unset;
      min-width: unset;
    }

    .react-multiple-carousel__arrow:hover {
      background: none;
    }

    .react-multiple-carousel__arrow::before {
      font-size: 20px;
      color: #fff;
      display: block;
      text-align: center;
      z-index: 2;
      position: relative;
    }

    .react-multiple-carousel__arrow:disabled {
      cursor: default;
      background: rgba(0, 0, 0, 0.5);
    }

    .react-multiple-carousel__arrow--left {
      left: 0;
    }

    .react-multiple-carousel__arrow--left::before {
      content: "<";
    }

    .react-multiple-carousel__arrow--right {
      right: 0;
    }

    .react-multiple-carousel__arrow--right::before {
      content: ">";
    }

    .react-multi-carousel-dot-list {
      position: absolute;
      bottom: 0;
      display: flex;
      left: 0;
      right: 0;
      justify-content: center;
      margin: auto;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
    }

    .react-multi-carousel-dot button {
      ${dotsStyle};
    }

    .react-multi-carousel-dot--active button {
      background-color: ${theme.colors.grey.grey100};
      border-color: ${theme.colors.grey.grey100};
    }

    .react-multi-carousel-item {
      transform-style: preserve-3d;
      backface-visibility: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .react-multi-carousel-item {
        flex-shrink: 0 !important;
      }

      .react-multi-carousel-track {
        overflow: visible !important;
      }
    }
  `}
`;
