import styled, { css } from "styled-components";
import {
  ISelectProps,
  Select as BaseSelect,
  SelectStyles
} from "@diana-ui/select";
import { ChipInput, ChipInputStyles } from "@ddm-design-system/chip";
import { Dropdown } from "@ddm-design-system/dropdown";
import {
  ErrorTextInputStyles,
  TextInputStyles
} from "@ddm-design-system/textinput";

const Select = styled(BaseSelect).attrs({
  CustomChipInputComponent: ChipInput,
  CustomDropdownComponent: Dropdown
})(
  ({ theme }) => css`
    .open {
      ${ChipInputStyles.StyledErrorTextInput} {
        ${ErrorTextInputStyles.StyledBaseTextInput} {
          border-radius: 4px 4px 0 0;
        }
      }
    }

    &.top .open {
      ${ChipInputStyles.StyledErrorTextInput} {
        ${ErrorTextInputStyles.StyledBaseTextInput} {
          border-radius: 0 0 4px 4px;
          border-top: 0;

          ${TextInputStyles.Legend} {
            display: none;
          }

          ${TextInputStyles.TextLabel} {
            display: none;
          }
        }
      }
    }
  `
);

Select.displayName = "Select";

export type { ISelectProps };

export { SelectStyles };

export default Select;
