import styled from "styled-components";
import { ChipInput } from "@diana-ui/chip";
import { Dropdown } from "@diana-ui/dropdown";
import { PopoverStyles } from "@diana-ui/popover";
import { ErrorTextInputStyles, TextInputStyles } from "@diana-ui/textinput";

/**
 * Component dropdown
 */
export const StyledDropdown = styled(Dropdown)`
  ${PopoverStyles.Container} {
    &.disabled {
      pointer-events: initial;
    }
  }
`;

export const StyledChipInput = styled(ChipInput)`
  ${TextInputStyles.Input} {
    &.disabled {
      pointer-events: initial;
    }
  }
  ${ErrorTextInputStyles.HelperLabel} {
    display: none;
  }
`;
