import React, { useState, useRef, useEffect } from "react";
import { useResizeObserver } from "@diana-ui/hooks";
import { IIconProps } from "@diana-ui/icon";
import * as TextInputStyles from "./TextInput.style";

export interface IProps {
  label?: string;
  hasError?: boolean;
  prefixIcon?: string | JSX.Element;
  suffixIcon?: string | JSX.Element;
  focusLabel?: boolean;
}

export type ITextInputProps = IProps & JSX.IntrinsicElements["input"];

const TextInput: React.FC<ITextInputProps> = ({
  className,
  hasError,
  focusLabel,
  label = "",
  onChange,
  disabled,
  prefixIcon,
  suffixIcon,
  ref,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasContent, setHasContent] = useState(false);
  const [legendWidth, setLegendWidth] = useState(0);
  const hiddenLabel = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const length = props.value?.toString()?.length || 0;
    setHasContent(length > 0);
  }, [props.value]);

  // observer that keeps track of hidden label width and sets legendWidth accordingly
  const labelResizeObserver = useResizeObserver(
    entries => {
      entries.forEach(entry => {
        const { width } = entry.contentRect;

        if (width !== legendWidth) {
          setLegendWidth(width);
        }
      });
    },
    [legendWidth]
  );

  useEffect(() => {
    const hiddenLabelEl = hiddenLabel.current;

    if (hiddenLabelEl && labelResizeObserver) {
      labelResizeObserver.observe(hiddenLabelEl);
    }

    return () => {
      if (hiddenLabelEl && labelResizeObserver) {
        labelResizeObserver.unobserve(hiddenLabelEl);
      }
    };
  }, [hiddenLabel, labelResizeObserver]);

  return (
    <TextInputStyles.Fieldset
      className={[className, isFocused && "focus", hasContent && "active"]
        .filter(Boolean)
        .join(" ")}
      hasError={hasError}
      disabled={disabled}
    >
      <TextInputStyles.Legend
        focusLabel={focusLabel}
        isFocused={isFocused}
        label={label}
        hasContent={hasContent}
        legendWidth={legendWidth}
      >
        {label}
      </TextInputStyles.Legend>
      <TextInputStyles.HiddenLabel ref={hiddenLabel}>{label}</TextInputStyles.HiddenLabel>
      <TextInputStyles.LabelContainer>
        <TextInputStyles.TextLabel
          focusLabel={focusLabel}
          isFocused={isFocused}
          hasContent={hasContent}
          labelWithPrefix={!!prefixIcon}
        >
          {label}
        </TextInputStyles.TextLabel>
      </TextInputStyles.LabelContainer>
      <TextInputStyles.InputContainer>
        {typeof prefixIcon === "string" ? (
          <TextInputStyles.PrefixIcon name={prefixIcon as IIconProps["name"]} size={16} />
        ) : (
          prefixIcon && <TextInputStyles.PrefixWrapper>{prefixIcon}</TextInputStyles.PrefixWrapper>
        )}
        <TextInputStyles.Input
          {...props}
          disabled={disabled}
          onChange={e => {
            if (onChange) {
              onChange(e);
            }
            setHasContent(e.target.value.length > 0);
          }}
          onBlur={e => {
            setIsFocused(false);
            return props.onBlur?.(e);
          }}
          onFocus={e => {
            setIsFocused(true);
            return props.onFocus?.(e);
          }}
        />
        {typeof suffixIcon === "string" ? (
          <TextInputStyles.SuffixIcon name={suffixIcon as IIconProps["name"]} size={16} />
        ) : (
          suffixIcon && <TextInputStyles.SuffixWrapper>{suffixIcon}</TextInputStyles.SuffixWrapper>
        )}
      </TextInputStyles.InputContainer>
    </TextInputStyles.Fieldset>
  );
};

TextInput.displayName = "TextInput";

export { TextInputStyles };

export default TextInput;
