import styled, { css } from "styled-components";
import {
  Infoicon as BaseInfoicon,
  InfoiconStyles as BaseInfoiconStyles
} from "@diana-ui/infoicon";
import { ImageAvatar, Avatar } from "@ddm-design-system/avatar";

export { BaseInfoiconStyles };

export default styled(BaseInfoicon).attrs(props => ({
  CustomAvatarComponent:
    typeof props.avatarOptions?.img?.src === "string" ? ImageAvatar : Avatar,
  avatarOptions: { ...props.avatarOptions }
}))(
  ({ theme, vertical, withPadding, avatarOptions }) => css`
    display: flex;

    ${vertical &&
    css`
      flex-direction: column;
      align-items: center;
      text-align: center;
    `}

    ${withPadding &&
    css`
      border: 1px solid ${theme.colors.grey.greenish};
      border-radius: 10px;
      padding: 14px 24px;
    `}


  ${BaseInfoiconStyles.Text} {
      display: flex;
      margin-left: ${theme.spaceUnit.md};
      flex-direction: column;
      padding-top: 2px;

      ${vertical &&
      css`
        margin-left: unset;
        margin-top: ${theme.spaceUnit.xs};
      `}
    }

    ${BaseInfoiconStyles.Title} {
      color: ${theme.mode === "dark"
        ? theme.colors.white
        : theme.colors.grey.grey100};
      margin-bottom: ${theme.spaceUnit.xs};
      text-align: left;

      ${vertical &&
      css`
        color: ${theme.colors.grey.grey50};
        text-align: center;
      `}
    }

    ${BaseInfoiconStyles.Content} {
      color: ${theme.mode === "dark"
        ? theme.colors.grey.grey25
        : theme.colors.black};
      ${vertical &&
      css`
        margin-top: ${theme.spaceUnit.xs};
        color: ${theme.colors.white};
      `}
    }

    ${BaseInfoiconStyles.StyledAvatar} {
      ${theme.mode === "dark" &&
      css`
        background-color: ${theme.colors.white};
      `}

      ${avatarOptions?.backgroundColor &&
      css`
        background-color: ${avatarOptions.backgroundColor};
        border-color: ${avatarOptions.borderColor ||
        avatarOptions.backgroundColor};
      `}
    }
  `
);
