import styled, { css } from "styled-components";
import { BodyHighlight, DescriptionMedium } from "@diana-ui/typography";
import { Avatar } from "@diana-ui/avatar";
import type { IInfoIconProps } from "./Infoicon";

export const StyledAvatar = styled(Avatar)``;

/**
 * Component  Icon Wrapper
 */
export const InfoIconWrapper = styled.div<IInfoIconProps>(
  ({ withPadding }) => css`
    display: flex;

    ${withPadding &&
    css`
      border: 1px solid lightgrey;
      border-radius: 10;
      padding: 14px 24px;
    `}
  `
);

export const Text = styled.div<IInfoIconProps>(
  ({ theme }) => css`
    display: flex;
    margin-left: ${theme.spaceUnit.md};
    flex-direction: column;
    padding-top: ${theme.spaceUnit.xxs};
  `
);

export const Title = styled(DescriptionMedium)<IInfoIconProps>(
  ({ vertical }) => css`
    text-align: left;

    ${vertical &&
    css`
      text-align: center;
    `}
  `
);

export const Content = styled(BodyHighlight)<IInfoIconProps>(
  ({ vertical }) => css`
    text-align: left;

    ${vertical &&
    css`
      text-align: center;
    `}
  `
);
