import styled, { css } from "styled-components";
import {
  CardHeaderStyles as BaseCardHeaderStyles,
  CardHeader as BaseCardHeader
} from "@diana-ui/card";
import { Divider } from "@ddm-design-system/divider";
import { Icon } from "@ddm-design-system/icon";
import { SectionTitle } from "@ddm-design-system/typography";

export const StyledBaseCardHeader = styled(BaseCardHeader)(
  ({ theme }) => css`
    padding: ${theme.spaceUnit.lg};

    ${BaseCardHeaderStyles.CardIcon} {
      margin-top: ${theme.spaceUnit.xs};
    }

    ${BaseCardHeaderStyles.StyledLabelMedium} {
      margin-top: ${theme.spaceUnit.xs};
    }
  `
);

export const DividerWrapper = styled.div(
  ({ theme }) => css`
    padding: 0px ${theme.spaceUnit.lg};
  `
);

export const StyledDivider = styled(Divider)``;

export const StyledSpan = styled.span``;

export const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    margin-right: ${theme.spaceUnit.xs};
  `
);

export const StyledSectionTitle = styled(SectionTitle)``;

export { BaseCardHeaderStyles };
